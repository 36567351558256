import {
  Addons,
  AdminUser,
  ClientUser,
  Features,
  Package,
  Retailer,
} from '../common/definitions';

export function hasPackage(
  user: ClientUser | AdminUser | null,
  package_name: Package,
) {
  let retailer_package = '';
  if (user?.role === 'client') {
    retailer_package = user?.currentRetailer?.package;
  } else {
    retailer_package = user?.details?.retailer?.package ?? '';
  }
  return retailer_package === package_name;
}

export function hasAddon(user: ClientUser | AdminUser, addon: Addons) {
  let result = false;
  if (typeof window === 'object' && window.localStorage) {
    result = window.localStorage.getItem(addon) === 'true';
  }

  if (result) {
    return result;
  }
  let apps = '';
  if (user?.role === 'client') {
    apps = user?.currentRetailer?.apps;
  } else {
    apps = user?.details?.retailer?.apps;
  }

  try {
    const parsedApps: string[] = JSON.parse(apps);
    return parsedApps.indexOf(addon) > -1;
  } catch {}

  return (apps || '').indexOf(addon) > -1;
}

export function retailerHasFeature(retailer: Retailer, feature: Features) {
  let result = false;
  if (typeof window === 'object' && window.localStorage) {
    result = window.localStorage.getItem(feature) === 'true';
  }

  if (result) {
    return result;
  }

  const features: string = JSON.parse(retailer.features);
  try {
    const parsedFeatures: string[] = JSON.parse(features);
    return parsedFeatures.indexOf(feature) > -1;
  } catch {}

  return (features || '').indexOf(feature) > -1;
}

export function hasFeature(user: ClientUser | AdminUser, feature: Features) {
  let result = false;
  if (typeof window === 'object' && window.localStorage) {
    result = window.localStorage.getItem(feature) === 'true';
  }

  if (result) {
    return result;
  }

  let features = '';
  if (user?.role === 'client') {
    features = user?.currentRetailer?.features;
  } else {
    features = user?.details?.retailer?.features;
  }

  try {
    const parsedFeatures: string[] = JSON.parse(features);
    return parsedFeatures.indexOf(feature) > -1;
  } catch {}

  return (features || '').indexOf(feature) > -1;
}
